import React, { useEffect } from "react";
import { useParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from "../../hooks/useRedux";
import { getMemoryPage } from "../../store/memorySlice";
import { baseURL } from "../../models/api.model";
import { SignHistoryItem } from "../../components/SignHistoryItem";
import C from "./MemoryPage.module.scss";
import './content.css'


export const MemoryPage = () => {
    const dispatch = useAppDispatch();
    const page = useAppSelector((state) => state.memory.page);
    var params = useParams();

    var memory_id: string = params.memory_id as string;

    useEffect(() => {
        dispatch(getMemoryPage(memory_id));
    }, [ dispatch, memory_id ]);

    const typeDisplay: { [index: number]: string } = {
        1: 'Знак памяти',
        2: 'Фотоистория',
        3: 'Искусство',
        4: 'Выставка',
    };
    const statusDisplay : { [index: number]: string } = {
        0: 'Знак в разработке',
        1: 'Знак установлен',
        2: 'Факт установки подтверждён',
    };

    var iconhref = () => {
        if (page) {
            return require(`../../assets/images/group${page.group}_${page.status}.svg`);
        }
        return '';
    }

    return (
        <div className={C.MemoryPage}>
            {page && (
            <>
                <h1>Страница AR-объекта в реестре</h1>
                <section>
                    <header>Название объекта</header>
                    <h2>{page.name}</h2>
                </section>

                <section data-grid="2">
                    <section>
                        <img src={baseURL + page.preview_img} alt="" draggable="false" />
                    </section>
                    <section>
                        <header>AR-объект</header>
                        <p>ID: {page.id}</p>
                    </section>
                    <section>
                        <header>Регион</header>
                        <p>{page.region}</p>
                    </section>
                    <section>
                        <header>Населённый пункт</header>
                        <p>{page.settlement}</p>
                    </section>
                    <section>
                        <header>Тип объекта</header>
                        <p>{typeDisplay[page.group]}</p>
                    </section>
                    <section>
                        <header>AR-статус</header>
                        <p style={{display:'flex',alignItems: 'center', marginLeft: '-64px'}}><img className={C.StatusIcon} src={iconhref()} /> {statusDisplay[page.status]}</p>
                    </section>
                </section>

                <section>
                    <header>Описание</header>
                    <div dangerouslySetInnerHTML={{__html: page.description}} />
                </section>

                <section>
                    <header>Геопространственные координаты</header>
                    <div style={{display: 'flex', flexWrap: 'wrap', gap: '10px 160px'}}>
                        <div>Широта<br/>{page.lng}</div>
                        <div>Долгота<br/>{page.lat}</div>
                    </div>
                </section>
               
                <section>
                    <header>Адрес</header>
                    <p>{page.address}</p>
                </section>

                {page.source && (
                    <section>
                         <header>Источник</header>
                         <p>{page.source}</p>
                    </section>
                )}

                {page.sponsors && (
                    <section>
                        <header>Организаторы / Партнёры</header>
                        <div dangerouslySetInnerHTML={{__html: page.sponsors}} />
                    </section>
                )}

                {page.developers && (
                    <section>
                        <header>Авторы / Разработчики</header>
                        <div dangerouslySetInnerHTML={{__html: page.developers}} />
                    </section>
                )}

                {page.about_model && (
                    <section>
                        <header>Информация о 3D-модели</header>
                        <div dangerouslySetInnerHTML={{__html: page.about_model}} />
                    </section>
                )}

                {page.history.length > 0 && (
                    <section>
                        <header>История разработки</header>

                        {page.history.map((item: any) => {
                            return (<SignHistoryItem item={item} group={page.group} key={item.id}/>)
                        })}
                    </section>
                )}

                {page.links && (
                    <section>
                        <header>Ссылки</header>
                        <div dangerouslySetInnerHTML={{__html: page.links}} />
                    </section>
                )}

                {page.extra_info && (
                    <section>
                        <header>Дополнительная информация</header>
                        <div dangerouslySetInnerHTML={{__html: page.extra_info}} />
                    </section>
                )}

            </>)}
        </div>
    );
};
