import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { useHttp } from "../hooks/http.hook";
import { ApiRequestMethodEnum, ApiRequestUrl, ApiStatusPendingEnum, ICard, } from "../models/api.model";
import network from "../api/network";

interface IMapState {
    center: number[];
    zoom: number;
    suppressMapOpenBlock: boolean;
    width: string;
}

interface IMemoryDetails {
    id: number;
    name: string;
    group: number;
    status: number;
    description: string;
    source: string,
    sponsors: string;
    developers: string;
    about_model: string;
    links: string;
    extra_info: string;
    project: string | null;
    address: string;
    region: string;
    settlement: string;
    lat: number;
    lng: number;
    images: string[];
    history: any[];
    preview_img: string;
}

interface IMemoryState {
    list: ICard[];
    mapState: IMapState;
    page: IMemoryDetails | null,
    selectedItem: ICard | null;
    isMobileSearch: boolean;
    status: ApiStatusPendingEnum | null;
    statusCreate: ApiStatusPendingEnum | null;
    statusPage: ApiStatusPendingEnum | null;
}

const initialState: IMemoryState = {
    list: [],
    mapState: {
        center: [ 52.27694444, 104.2855556 ],
        zoom: 3,
        suppressMapOpenBlock: true,
        width: "100%",
    },
    page: null,
    selectedItem: null,
    isMobileSearch: false,
    status: null,
    statusCreate: null,
    statusPage: null,
};

export const getMemory = createAsyncThunk("memory/getMemory", async () => {
    const { request } = useHttp();
    return await request(ApiRequestMethodEnum.GET, ApiRequestUrl.getMemory);
});

export const getMemoryPage = createAsyncThunk("memory/getMemoryPage", async (memory_id: string) => {
    const { request } = useHttp();
    return await request(ApiRequestMethodEnum.GET, ApiRequestUrl.getMemory + memory_id);
});

export const createMemory = createAsyncThunk(
    "memory/createMemory",
    async (data: any) => {
        return await network.post(ApiRequestUrl.createMemory, data, {
            headers: { 'Content-Type': 'multipart/form-data', }
        });
    }
);

const memorySlice = createSlice({
    name: "memory",
    initialState,
    reducers: {
        updateZoom(state, action: PayloadAction<number>) {
            state.mapState.zoom = action.payload;
        },
        selectPlacemark(state, action: PayloadAction<ICard>) {
            state.mapState.zoom = 15;
            state.mapState.center = [ action.payload.lat, action.payload.lng ];
            state.selectedItem = action.payload;
        },
        resetMap(state) {
            state.mapState = initialState.mapState;
            state.selectedItem = initialState.selectedItem;
        },
        openSearch(state) {
            state.isMobileSearch = true;
        },
        closeSearch(state) {
            state.isMobileSearch = false;
        },
        resetStatusForm(state) {
            state.statusCreate = null;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(
                getMemory.pending,
                (state) => {
                    state.status = ApiStatusPendingEnum.LOADING;
                })
            .addCase(
                getMemory.fulfilled,
                (state, action: PayloadAction<Array<ICard>>) => {
                    state.status = ApiStatusPendingEnum.LOADED;
                    state.list = action.payload;
                }
            )
            .addCase(
                getMemory.rejected,
                (state) => {
                    state.status = ApiStatusPendingEnum.ERROR;
                })
            .addCase(
                getMemoryPage.pending,
                (state) => {
                    state.statusPage = ApiStatusPendingEnum.LOADING;
                })
            .addCase(
                getMemoryPage.fulfilled,
                (state, action: PayloadAction<IMemoryDetails>) => {
                    state.statusPage = ApiStatusPendingEnum.LOADED;
                    state.page = action.payload;
                }
            )
            .addCase(
                getMemoryPage.rejected,
                (state) => {
                    state.statusPage = ApiStatusPendingEnum.ERROR;
                })
            .addCase(
                createMemory.pending,
                (state) => {
                    state.statusCreate = ApiStatusPendingEnum.LOADING;
                })
            .addCase(
                createMemory.fulfilled,
                (state) => {
                    state.statusCreate = ApiStatusPendingEnum.LOADED;
                }
            )
            .addCase(
                createMemory.rejected,
                (state) => {
                    state.statusCreate = ApiStatusPendingEnum.ERROR;
                }
            );
    },
});

export const {
    updateZoom,
    selectPlacemark,
    resetMap,
    openSearch,
    closeSearch,
    resetStatusForm
} = memorySlice.actions;
export default memorySlice.reducer;
