import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    isOpenMenu: false,
};

const userInterfaceSlice = createSlice({
    name: 'userInterface',
    initialState,
    reducers: {
        openModal(state) {
            state.isOpenMenu = true;
        },
        closeModal(state) {
            state.isOpenMenu = false;
        },
    },
});

export const {
    openModal,
    closeModal
} = userInterfaceSlice.actions;
export default userInterfaceSlice.reducer;