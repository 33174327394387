import React, { FC, useEffect } from "react";
import { Header } from "../../components/Header";
import { Footer } from "../../components/Footer";
import { useAppSelector } from "../../hooks/useRedux";
import { Menu } from "../Menu";
import { Side } from "../Side";
import { useMediaQuery } from "@react-hook/media-query";
import { Media } from "../../models/media";
import C from './Layout.module.scss';

import {useNavigate} from 'react-router';

interface LayoutProps {
    children: React.ReactNode | React.ReactNode[];
}

export const Layout: FC<LayoutProps> = ({ children }) => {
    const isOpenSearch = useAppSelector((state) => state.memory.isMobileSearch);
    const isOpenMenu = useAppSelector((state) => state.userInterface.isOpenMenu);
    const isMobile = useMediaQuery(`only screen and (max-width: ${Media.SM}px)`);

    // catch raw html link clicks and redirect them to react router
    // it it is an internal link
    var navigate = useNavigate();
    function handleClick(e: any) {
        if (e.target.nodeName == 'A') {
            const href = e.target.href;
            if(href.indexOf(window.location.hostname) === -1)
                return true;
            if(href.indexOf('#') !== -1)
                return true;

            e.preventDefault();
            navigate(e.target.pathname);
        }
    }
    useEffect(() => {
        document.addEventListener('click', handleClick);
        return () => {
            document.removeEventListener('click', handleClick);
        }
    });

    return (
        <>
            <div className={C.Layout}>
                <Header />

                <main className={C.Layout__Content}>
                    {(isOpenSearch || !isMobile) && (
                        <Side />
                    )}
                    {!isOpenSearch && (
                        <div className={C.Layout__Right}>{children}</div>
                    )}
                </main>

                {!isOpenSearch && <Footer />}
            </div>

            <Menu open={isOpenMenu} />
        </>

    );
};
