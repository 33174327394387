import React, { useCallback, useState } from 'react';
import C from "../../pages/Home/Home.module.scss";
import { Search } from "../Search";
import { MemoryInfo } from "../MemoryInfo";
import { useAppDispatch, useAppSelector } from "../../hooks/useRedux";
import { useMediaQuery } from "@react-hook/media-query";
import { Media } from "../../models/media";
import { closeSearch } from "../../store/memorySlice";
import { NavLink, useNavigate } from "react-router-dom";

export const Side = () => {
    const isOpenSearch = useAppSelector((state) => state.memory.isMobileSearch);
    const isMobile = useMediaQuery(`only screen and (max-width: ${Media.SM}px)`);
    const selectedItem = useAppSelector((state) => state.memory.selectedItem);
    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    const [ isOpenModal, setIsOpenModal ] = useState(false);

    const handleCloseSearch = useCallback(() => {
        dispatch(closeSearch());
        window.scrollTo(0, 0);
        navigate('/');
    }, [ dispatch, navigate ]);

    const handleCloseModalForm = useCallback(() => {
        setIsOpenModal(false);
    }, []);

    return (
        <>
            <div className={C.Home__Left}>
                <Search />
                <MemoryInfo />

                {isOpenSearch && isMobile && selectedItem && (
                    <button onClick={handleCloseSearch} className={C.Home__Button}>
                        Посмотреть на карте
                    </button>
                )}

                {!isMobile && (
                    <div className={C.Home__ButtonGroup}>
                        <NavLink
                            className={C.Home__Button}
                            to="/about"
                        >
                            О проекте
                        </NavLink>

                        <NavLink
                            className={C.Home__Button}
                            to="/add-marker"
                        >
                            ДОБАВИТЬ СВОЙ ЗНАК ПАМЯТИ
                        </NavLink>
                    </div>
                )}
            </div>
        </>
    );
};