import React, { useEffect } from "react";
import { useParams, useLocation, redirect } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from "../../hooks/useRedux";
import { LayoutPage } from "../../containers/Layout";
import { getProjectDetails } from "../../store/projectsSlice";

export const ProjectDetails = () => {
    const dispatch = useAppDispatch();
    var params = useParams();
    var location = useLocation();

    var project_id: string = params.project_id as string;
    var project_slug: string = params.slug as string;

    const page = useAppSelector((state) => state.projects.projectDetails);

    useEffect(() => {
        dispatch(getProjectDetails(project_id));
    }, [ dispatch, project_id ]);

    // TODO: move to router.loader
    // useEffect(() => {
    //     console.log('page effect', page)
    //     if (page) {
    //         var canonical_path = `/projects/${page.id}/${page.slug}`;
    //         if (location.pathname != canonical_path) {
    //             console.log('redirect')
    //             redirect(canonical_path);
    //         }
    //     }
    // }, [page])

    return (
        <LayoutPage>
        {page && (
        <>
            <h1>{page.name}</h1>
            <div dangerouslySetInnerHTML={{__html: page.full_description}}/>
        </>
        )}
        <h3>СВЯЗАТЬСЯ С РАЗРАБОТЧИКАМИ:</h3>
        <p>
            <a href="mailto:arwayteam@yandex.ru">arwayteam@yandex.ru</a> <br />
            <a href="mailto:ar-team.kaliningrad@yandex.ru">ar-team.kaliningrad@yandex.ru</a>
        </p>
        </LayoutPage>
    );
};
