import { useCallback, useRef } from "react";
import { Map, Placemark, TypeSelector } from "react-yandex-maps";
import C from "./MapApp.module.scss";
import { useAppDispatch, useAppSelector } from "../../hooks/useRedux";
import { selectPlacemark, updateZoom } from "../../store/memorySlice";
import { useMediaQuery } from "@react-hook/media-query";
import { Media } from "../../models/media";
import { ICard } from "../../models/api.model";

export const MapApp = () => {
    const dispatch = useAppDispatch();
    const mapRef = useRef<any>(null);
    const isMobile = useMediaQuery(`only screen and (max-width: ${Media.SM}px)`);
    const selectedItem = useAppSelector((state) => state.memory.selectedItem);

    const data = useAppSelector((state) => state.memory.list);
    const mapState = useAppSelector((state) => state.memory.mapState);

    const handleUpdateZoom = useCallback(() => {
        dispatch(updateZoom(mapRef.current?.getZoom()));
        mapRef.current?.balloon.close();
    }, [ dispatch ]);

    const handlePlacemarkClick = useCallback(
        (point: ICard) => () => {
            dispatch(selectPlacemark(point));
        },
        [ dispatch ]
    );

    return (
        <div
            className={`${C.MapApp} ${
                isMobile && selectedItem && C.MapApp__Selected
            } ${C.MapAppContainer}`}
        >
            <Map
                className={`${C.MapApp} ${
                    isMobile && selectedItem && C.MapApp__Selected
                } `}
                state={mapState}
                instanceRef={(ref) => {
                    mapRef.current = ref;
                    if (ref && isMobile) {
                        const map: any = ref;
                        map.behaviors.disable('drag');
                        map.behaviors.enable('multiTouch');
                    }
                }}
                onBoundsChange={handleUpdateZoom}
                options={{
                    suppressMapOpenBlock: true,
                    autoFitToViewport: "always",
                    minZoom: 3
                }}
            >
                {data?.map((item) => (
                    <Placemark
                        key={item.id}
                        geometry={[ item.lat, item.lng ]}
                        properties={{
                            balloonContent: !isMobile && item.name,
                        }}
                        options={{
                            iconLayout: "default#image",
                            iconImageHref: require(`../../assets/images/group${item.group}_${item.status}.svg`),
                            iconImageSize: [
                                item.group === 2 ? mapState.zoom * 3 : mapState.zoom * 6,
                                item.group === 2 ? mapState.zoom * 3 : mapState.zoom * 6,
                            ],
                            iconImageOffset: [
                                item.group === 2 ? -mapState.zoom : -mapState.zoom * 2.5,
                                item.group === 2 ? -mapState.zoom : -mapState.zoom * 4,
                            ],
                        }}
                        modules={[ "geoObject.addon.balloon", "geoObject.addon.hint" ]}
                        onClick={handlePlacemarkClick(item)}
                    />
                ))}

                <TypeSelector
                    options={{
                        float: "right",
                    }}
                />
            </Map>
        </div>
    );
};
