import { useCallback, useMemo, useRef, useState } from "react";
import C from "./Search.module.scss";
import Select from "react-select";
import { useAppDispatch, useAppSelector } from "../../hooks/useRedux";
import { resetMap, selectPlacemark } from "../../store/memorySlice";
import { useMediaQuery } from "@react-hook/media-query";
import { Media } from "../../models/media";
import { useNavigate } from "react-router-dom";

interface SelectedPoint {
    value: string;
    label: string;
}

export const Search = () => {
    const dispatch = useAppDispatch();
    const isMobile = useMediaQuery(`only screen and (max-width: ${Media.SM}px)`);
    const data = useAppSelector((state) => state.memory.list);
    const selectedItem = useAppSelector((state) => state.memory.selectedItem);
    const navigate = useNavigate();

    const [ selectValue, setSelectValue ] = useState<SelectedPoint | null>(
        selectedItem
            ? {
                value: !!selectedItem?.id ? selectedItem?.id.toString() : "",
                label: `${selectedItem.address}, ${selectedItem.name}`,
            }
            : null
    );

    const selectInputRef = useRef(null);

    const options = useMemo(
        () =>
            data
                ?.map((item) => ({
                    value: item?.id ? item?.id.toString() : "",
                    label: `${item.address}, ${item.name}`,
                }))
                .sort(
                    (a, b) =>
                        a.label.localeCompare(b.label) || a.label.localeCompare(b.label)
                ) || [],
        [ data ]
    );

    const handleClickButton = useCallback(() => {
        dispatch(resetMap());
        setSelectValue(null);
        window.scrollTo(0, 0);
        navigate('/');
    }, [ dispatch, navigate ]);

    const handleChange = useCallback(
        (selectedOption: any) => {
            const point = data.filter(
                (item) => item.id === Number(selectedOption.value)
            )[0];
            dispatch(selectPlacemark(point));
            setSelectValue(selectedOption);
        },
        [ data, dispatch ]
    );

    return (
        <div className={C.Search}>
            <div className={C.Search__Head}>
                <Select
                    ref={selectInputRef}
                    className={C.Search__Input}
                    options={options}
                    value={selectValue}
                    placeholder="Поиск"
                    noOptionsMessage={({ inputValue }) =>
                        !inputValue ? "Нет данных" : "Ничего не найдено"
                    }
                    onChange={handleChange}
                />
                {!isMobile && (
                    <button onClick={handleClickButton} className={C.Search__Button}>
                        Карта
                    </button>
                )}
            </div>
        </div>
    );
};
