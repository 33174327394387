import React, { FC } from 'react';
import C from './MemoryInfo.module.scss';
import { useAppSelector } from "../../hooks/useRedux";
import defaultImage from '../../assets/images/logo-red.svg';
import { baseURL } from "../../models/api.model";

interface MemoryInfoProps {
    isFull?: boolean;
}

export const MemoryInfo: FC<MemoryInfoProps> = ({ isFull }) => {
    const selectedItem = useAppSelector((state) => state.memory.selectedItem);

    console.log(selectedItem);

    return (
        <div className={`${C.MemoryInfo} ${isFull && C.MemoryInfo__Full}`}>
            <>
                {selectedItem && (
                    <>
                        <h3 className={C.MemoryInfo__Title}>{selectedItem?.name}</h3>

                        <div className={C.MemoryInfo__Wrap}>
                            <div className={C.MemoryInfo__Left}>
                                <p className={C.MemoryInfo__Desc}>{selectedItem?.description}</p>
                            </div>

                            <div className={C.MemoryInfo__Right}>
                                <img
                                    className={C.MemoryInfo__Image}
                                    src={`${baseURL}/${selectedItem?.preview_img}`}
                                    alt={selectedItem?.name} />
                            </div>

                            <div className={C.MemoryInfo__Address}>{selectedItem?.address}</div>
                        </div>
                    </>
                )}

                {!selectedItem && (
                    <img className={C.MemoryInfo__Default} src={defaultImage} alt="" />
                )}
            </>

        </div>
    );
};