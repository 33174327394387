import React from 'react';
import { LayoutPage } from "../../containers/Layout";
import C from './AboutApp.module.scss';

import image1 from '../../assets/images/aboutApp/1.png';
import image2 from '../../assets/images/aboutApp/2.png';
import image3 from '../../assets/images/aboutApp/3.png';
import image4 from '../../assets/images/aboutApp/4.png';
import image5 from '../../assets/images/aboutApp/5.png';
import apple from '../../assets/images/aboutApp/apple.png';
import google from '../../assets/images/aboutApp/google.png';

export const AboutApp = () => {
    return (
        <LayoutPage>
            <h1>О МОБИЛЬНОМ ПРИЛОЖЕНИИ <br /> «ЗНАК ПАМЯТИ AR»</h1>

            <p>"Знак Памяти" - суперапп дополненной и расширенной реальности для всех, кто занимается сохранением,
                восстановлением и популяризацией памятников истории и культуры России. </p>

            <div className={C.Images}>
                <img src={image1} alt="" />
                <img src={image2} alt="" />
                <img src={image3} alt="" />
                <img src={image4} alt="" />
                <img src={image5} alt="" />
            </div>

            <p>Мобильное приложение "Знак Памяти" включает 3 функциональных раздела для создания опыта взаимодействия с
                дополненной и расширенной реальностью (AR/XR):
            </p>

            <h2>AR-СЛОЙ</h2>

            <p>Слой дополненной реальности на территории России, в котором по координатам GPS размещаются виртульные
                памятные знаки в честь утраченных объектах культурного наследия - памятниках истории и культуры, а также
                другие 3d объекты (фото и видео инсталляции, реконструкции, выставки), посвященные событиям истории. В
                этом слое пользователь находит AR-объекты с помощью радара, осматривает их, делает фото и видеосъемку,
                получает дополнительную информацию об объектах, не выходя из режима дополненной реальности.
            </p>

            <h2>ДЕМО ГАЛЕРЕЯ AR-ЗНАКОВ</h2>

            <p>Если после включения супераппа радар не найдет AR-знаков в радиусе 500 метров, пользователь сможет
                выбрать "Демо режим" и посмотреть избранные примеры AR-знаков, открыв их в дополненной реальности
                непосредственно рядом с собой.
            </p>

            <h2>ОЦИФРОВКА РЕАЛЬНОСТИ ВОКРУГ AR-ЗНАКА</h2>

            <p>В режиме "Оцифровка" пользователь может сфотографировать реальное пространство вокруг места AR-знака,
                применяя специальный режим съемки.
                Результаты съемки автоматически обрабатаются с помощью Искусственного Интеллекта и методов компьютерного
                зрения для формирования цифровой 3D-карты окрестностей. Это будет очень полезно в будущем для точной
                установки виртуального AR-знака в пространстве реального мира.</p>

            <img src={apple} alt="" />
            <img src={google} alt="" />
        </LayoutPage>
    );
};