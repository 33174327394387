import React, { useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { Layout, LayoutStandalonePage } from "../Layout";
import { Home } from "../../pages/Home";
import { About } from "../../pages/About";
import { useAppDispatch } from "../../hooks/useRedux";
import { getMemory } from "../../store/memorySlice";
import { AddMarker } from "../../pages/AddMarker/AddMarker";
import { AboutApp } from "../../pages/AboutApp";
import { Registry } from "../../pages/Registry";
import { MemoryPage } from "../../pages/MemoryPage";
import { Projects } from "../../pages/Projects";
import { ProjectDetails } from "../../pages/ProjectDetails";
import { Roadmap } from "../../pages/Roadmap";


const App = () => {
    const dispatch = useAppDispatch();
    
    useEffect(() => {
        dispatch(getMemory());
    }, [ dispatch ]);

    return (
        <Router>
            <Routes>
                <Route path="/" element={<Layout children={<Home />} />} />
                <Route path="/about" element={<Layout children={<About />} />} />
                <Route path="/add-marker" element={<Layout children={<AddMarker />} />} />
                <Route path="/about-app" element={<Layout children={<AboutApp />} />} />
                <Route path="/registry" element={<LayoutStandalonePage children={<Registry />} />} />
                <Route path="/memory/:memory_id" element={<Layout children={<MemoryPage />} />} />

                <Route path="/projects" element={<Layout children={<Projects />} />} />
                <Route path="/projects/:project_id/:slug" element={<Layout children={<ProjectDetails />} />} />

                <Route path="/roadmap" element={<Layout children={<Roadmap />} />}/>
            </Routes>
        </Router>
    );
};

export default App;