import React, { useEffect, useState, useCallback } from "react";
import { useAppDispatch, useAppSelector } from "../../hooks/useRedux";
import { useMediaQuery } from "@react-hook/media-query";
import { NavLink } from "react-router-dom";
import Select from "react-select";
import { getRegistry } from "../../store/registrySlice";
import { Media } from "../../models/media";
import C from "./Registry.module.scss";
import { RegistryNode } from '../../containers/RegistryNode'


export const Registry = () => {

    const dispatch = useAppDispatch();
    const registry = useAppSelector(state => state.registry.registry);

    const [signType, setSignType] = useState(-1);
    const [signStatus, setSignStatus] = useState(-1);

    useEffect(() => {
        dispatch(getRegistry({typeFilter: signType, statusFilter: signStatus}));
    }, [ dispatch, signType, signStatus ]);

    const typeOptions = [
        { label: 'Все типы', value: -1 },
        { label: 'Знак памяти', value: 1 },
        { label: 'Фотоистория', value: 2 },
        { label: 'Искусство', value: 3 },
        { label: 'Выставка', value: 4 },
    ];
    const statusOptions = [
        { label: 'Все статусы', value: -1 },
        { label: 'Объект в разработке', value: 0 },
        { label: 'Объект установлен', value: 1 },
        { label: 'Факт установки подтверждён', value: 2 },
    ];


    const handleTypeChange = useCallback(
        (option: any) => {
            setSignType(option.value);
        },
        []
    );

    const handleStatusChange = useCallback(
        (option: any) => {
            setSignStatus(option.value);
        },
        []
    );

    return (
        <div className={`${C.Registry}`}>
            <div className={C.Registry__Filter}>
                <Select options={typeOptions} defaultValue={typeOptions[0]} isSearchable={false} onChange={handleTypeChange} />
                <Select options={statusOptions} defaultValue={statusOptions[0]} isSearchable={false} onChange={handleStatusChange}/>
            </div>
        
            {
                registry.map((d: any) => (<RegistryNode key={d.name} path={[]} name={d.name} items={d.items} />) )
            }
        </div>
    );
};
