import React, { FC, useCallback } from 'react';
import C from './Menu.module.scss';
import { NavLink } from "react-router-dom";
import { IconButton } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import { useAppDispatch } from "../../hooks/useRedux";
import { closeModal } from "../../store/userInterfaceSlice";

interface MenuProps {
    open: boolean;
}

export const Menu: FC<MenuProps> = ({ open }) => {
    const dispatch = useAppDispatch();

    const closeMenu = useCallback(() => {
        dispatch(closeModal());
    }, [ dispatch ]);

    return (
        <>
            <div className={`${C.Menu} ${open && C.Menu__Open}`}>
                <IconButton className={C.Close} color="default" onClick={closeMenu}>
                    <CloseIcon />
                </IconButton>

                <NavLink
                    className={({ isActive }) => `${C.Menu__Item} ${isActive && C.Menu__Item_Active}`}
                    to="/"
                    onClick={closeMenu}
                >
                    ГЛАВНАЯ
                </NavLink>

                <NavLink
                    className={({ isActive }) => `${C.Menu__Item} ${isActive && C.Menu__Item_Active}`}
                    to="/about"
                    onClick={closeMenu}
                >
                    О ПЛАТФОРМЕ «ЗНАК ПАМЯТИ»
                </NavLink>

                <NavLink
                    className={({ isActive }) => `${C.Menu__Item} ${isActive && C.Menu__Item_Active}`}
                    to="/about-app"
                    onClick={closeMenu}
                >
                    О МОБИЛЬНОМ ПРИЛОЖЕНИИ «ЗНАК ПАМЯТИ AR»
                </NavLink>

                <NavLink
                    className={({ isActive }) => `${C.Menu__Item} ${isActive && C.Menu__Item_Active}`}
                    to="/registry"
                    onClick={closeMenu}
                >
                    РЕЕСТР AR-ОБЪЕКТОВ
                </NavLink>

                <NavLink
                    className={({ isActive }) => `${C.Menu__Item} ${isActive && C.Menu__Item_Active}`}
                    to="/projects"
                    onClick={closeMenu}
                >
                    ИНИЦИАТИВНЫЕ И ПАРТНЕРСКИЕ ПРОЕКТЫ
                </NavLink>

                <NavLink
                    className={({ isActive }) => `${C.Menu__Item} ${isActive && C.Menu__Item_Active}`}
                    to="/roadmap"
                    onClick={closeMenu}
                >
                    ДОРОЖНАЯ КАРТА
                </NavLink>

                <NavLink
                    className={({ isActive }) => `${C.Menu__Item} ${isActive && C.Menu__Item_Active}`}
                    to="/add-marker"
                    onClick={closeMenu}
                >
                    РАЗМЕСТИТЬ СВОЙ AR-ОБЪЕКТ
                </NavLink>

                <NavLink
                    className={({ isActive }) => `${C.Menu__Item} ${C.Menu__Item_Disabled} ${isActive && C.Menu__Item_Active}`}
                    to="#"
                    onClick={closeMenu}
                >
                    ВОПРОСЫ И ОТВЕТЫ
                </NavLink>

                <NavLink
                    className={({ isActive }) => `${C.Menu__Item} ${C.Menu__Item_Disabled} ${isActive && C.Menu__Item_Active}`}
                    to="#"
                    onClick={closeMenu}
                >
                    ОРГАНИЗАТОРЫ И УЧАСТНИКИ
                </NavLink>

                <NavLink
                    className={({ isActive }) => `${C.Menu__Item} ${C.Menu__Item_Disabled} ${isActive && C.Menu__Item_Active}`}
                    to="#"
                    onClick={closeMenu}
                >
                    КОНТАКТЫ
                </NavLink>
            </div>

            <div className={`${C.Blur} ${open && C.Blur__Open}`} onClick={closeMenu} />
        </>
    );
};