import React, { useEffect } from "react";
import { MapApp } from "../../containers/MapApp";
import { MemoryInfo } from "../../containers/MemoryInfo";
import { useAppDispatch, useAppSelector } from "../../hooks/useRedux";
import { useMediaQuery } from "@react-hook/media-query";
import { Media } from "../../models/media";
import { getMemory } from "../../store/memorySlice";
import C from "./Home.module.scss";
import { NavLink } from "react-router-dom";

export const Home = () => {
    const isOpenSearch = useAppSelector((state) => state.memory.isMobileSearch);
    const isMobile = useMediaQuery(`only screen and (max-width: ${Media.SM}px)`);
    const selectedItem = useAppSelector((state) => state.memory.selectedItem);
    const dispatch = useAppDispatch();

    useEffect(() => {
        dispatch(getMemory());
    }, [ dispatch ]);

    return (
        <>

            {(!isOpenSearch || !isMobile) && (
                <MapApp />
            )}

            {!isOpenSearch && isMobile && selectedItem && <MemoryInfo isFull />}

            {isMobile && !isOpenSearch && (
                <div className={C.Home__ButtonGroup}>
                    <NavLink
                        className={C.Home__Button}
                        to="/about"
                    >
                        О проекте
                    </NavLink>

                    <NavLink
                        className={C.Home__Button}
                        to="/add-marker"
                    >
                        ДОБАВИТЬ СВОЙ ЗНАК ПАМЯТИ
                    </NavLink>
                </div>
            )}
        </>
    );
};
