import ReactDOM from "react-dom/client";
import "./styles/global.scss";
import App from "./containers/app/App";
import { Provider } from "react-redux";
import store from "./store";
import { YMaps } from "react-yandex-maps";

const root = ReactDOM.createRoot(
    document.getElementById("root") as HTMLElement
);
root.render(
    <Provider store={store}>
        <YMaps key="39f774fc-49ab-4206-a0b5-867de9c2f40c">
            <App />
        </YMaps>
    </Provider>
);
