import React, { FC, useState, useCallback } from "react";
import C from "./RegistryNode.module.scss";
import { baseURL } from "../../models/api.model";

interface RegistryNodeProps {
    path: Array<string>;
    name: string;
    items: Array<any>;
    level?: number;
}

export const RegistryNode: FC<RegistryNodeProps> = ({path, name, items, level=1}) => {
    const [isOpen, setOpen] = useState(false);

    const handleClick = useCallback(() => {
        setOpen(!isOpen);
    }, [isOpen]);

    const typeDisplay: { [index: number]: string } = {
        1: 'Знак памяти',
        2: 'Фотоистория',
        3: 'Искусство',
        4: 'Выставка',
    };
    const statusDisplay : { [index: number]: string } = {
        0: 'Знак в разработке',
        1: 'Знак установлен',
        2: 'Факт установки подтверждён',
    };


    return (
        <div className={C.RegistryNode} data-level={level}>
            <div className={C.RegistryNode__Title} onClick={handleClick}>
                {name}
            </div>
            <div className={`${C.RegistryNode__Items} ${isOpen ? C.RegistryNode__Items__Open: ''}`}>
            {
                items.map(
                    (d: any) => {
                        var subpath = [...path, d.name];
                        if (d.items) {
                            return (<RegistryNode key={d.name} path={subpath} name={d.name} items={d.items} level={level+1}/>);
                        } else {
                            var imghref = require(`../../assets/images/group${d.group}_${d.status}.svg`);
                            return (<a href={'/memory/' + d.id} target="_blank" className={C.RegistryNode__Card} key={d.name}>
                                <img src={baseURL + d.preview_img} alt="" /> 
                                <header>{d.name}</header>
                                <article>
                                    <img src={imghref} /> {typeDisplay[d.group]}
                                </article>
                            </a>);
                        }
                    }
                )
            }
            </div>
        </div>
    );
};
