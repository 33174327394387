import C from './Roadmap.module.scss';

export const Roadmap = () => {
    return (
    <div className={C.Roadmap}>
        <h1>Дорожная карта</h1>

        <section>
            <div className={C.date}>
                <header>Q2 2023</header>
                (2-й квартал 2023)
            </div>
            <div className={C.content}>
                <div className={C.timelineDot}></div>
                <div className={C.mobileDate}>
                    <header>Q2 2023</header>
                    (2-й квартал 2023)
                </div>
                
                <h2>1-й ЭТАП <span className={C.statusLabel}>выполнен</span></h2>
                
                <h3>ДЕЙСТВУЮЩИЙ AR-КОМПЛЕКС <span className={C.statusLabel}>создан</span></h3>

                <p>Цели 1-го ЭТАПА:</p>

                <p>
                    Создание работоспособного комплекса (действующего прототипа платформы), обеспечивающего размещение 
                    по геопространственным координатам неограниченного количества AR-объектов на территории РФ 
                    и&nbsp;онлайн-редактирование AR-объектов с использованием облачных сервисов;<br/>
                    поддержка интерактивного взаимодействия с AR-объектами потенциально неограниченного количества пользователей.
                </p>

                <p>В разработанный комплекс входят:</p>

                <ul>
                    <li>облачный сервер; </li>
                    <li>мобильные приложения (SuperApp) для Android и iOS;</li>
                    <li>интегрированная база данных. </li>
                </ul>

                <p>На этом этапе и до достижения целей 5-го и 6-го этапов, комплекс будет работать на внешних облачных IT-платформах.</p>

                <p>Фактические (тестовые) количественные результаты на 15.05.2023: на платформе размещено 400 интерактивных анимированных AR-объектов в 350 городах РФ.</p>
            </div>
        </section>

        <section>
            <div className={C.date}>
                <header>Q4 2023&ndash;<br/>&ndash;Q1 2024</header>
            </div>
            <div className={C.content}>
                <div className={C.timelineDot}></div>
                <div className={C.mobileDate}>
                    <header>Q4 2023 &ndash; Q1 2024</header>
                </div>
                <h2>2-й ЭТАП</h2>

                <h3>ОБЩЕДОСТУПНАЯ УСТАНОВКА И РЕДАКТИРОВАНИЕ AR-ОБЪЕКТОВ</h3>

                <p>Цели 2-го ЭТАПА:</p>

                <ul>
                <li>Визуальный no-code интерфейс установки и редактирования AR-объектов неограниченным кругом пользователей (без необходимости владения языками программирования) в выбранных геопространственных координатах (присутствие на месте установки не требуется) и на основе AR-маркеров (требуется присутствие на месте установки).</li>
                <li>Визуальное программирование параметров работы AR-маркеров.</li>
                <li>Функционал обновления в режиме реального времени на мобильных устройствах пользователей (доставка AR-маркеров и AR-объектов в уже опубликованные приложения, без необходимости их перепубликации). </li>
                <li>Воспроизведение AR-инсталляций на основе геопространственного позиционирования и маркеров в рамках единого AR-приложения.</li>
                </ul>
            </div>
        </section>

        <section>
            <div className={C.date}>
                <header>Q1-Q2 2024</header>
            </div>
            <div className={C.content}>
                <div className={C.timelineDot}></div>
                <div className={C.mobileDate}>
                    <header>Q1-Q2 2024</header>
                </div>
                <h2>3-й ЭТАП</h2>
                <h3>СИСТЕМА ПОЗИЦИОНИРОВАНИЯ: КОМПЬЮТЕРНОЕ ЗРЕНИЕ + ИИ</h3>
                <p>Цели 3-го ЭТАПА:</p>

                <ul>
                <li>Система визуального позиционирования на основе технологий компьютерного зрения и искусственного интеллекта для сверхточного размещения AR-объектов (система обучается на данных реконструкций архитектурных объектов, собираемых пользователями мобильного приложения). Повышает точность расположения AR-объектов за счет устранения неточных данных от датчиков GPS и электронного компаса. </li>
                <li>Веб-редактор для визуальной установки AR-объектов в пространстве цифровых архитектурных реконструкций. </li>
                <li>Облачная база данных с системой разграничения доступа для доступа и управления 3D моделями и цифровыми реконструкциями архитектурных объектов. </li>
                <li>Воспроизведение AR объектов на мобильных устройствах пользователей в привязке к цифровым реконструкциям. </li>
                </ul>
            </div>
        </section>

        <section>
            <div className={C.date}>
                <header>Q1-Q2 2024</header>
            </div>
            <div className={C.content}>
                <div className={C.timelineDot}></div>
                <div className={C.mobileDate}>
                    <header>Q1-Q2 2024</header>
                </div>
                <h2>4-й ЭТАП</h2>

                <h3>ОБЩЕДОСТУПНЫЙ РЕДАКТОР СЦЕНАРИЕВ AR-СОБЫТИЙ</h3>

                <p>Цели 4-го ЭТАПА:</p>

                <ul>
                <li>Редактор сценариев – комплексный инструмент по управлению интерактивными AR-инсталляциям. 
                    Редактор сценариев позволяет с помощью визуального no-code интерфейса создавать сценарии 
                    взаимодействия пользователей с объектами дополненной реальности.</li>
                <li>Функции визуального проектирования интерактивного пользовательского интерфейса, сопровождающего наблюдаемую AR-инсталляцию. </li>
                <li>Функции настройки и управления анимациями и поведением AR-моделей в 3D-пространстве. </li>
                <li>Настройка аудио и видео сопровождения AR/инсталляций (исторические хроники, аудиоэкскурсии). </li>
                <li>Реальные объекты архитектуры смогут дополняться деталями: аудио, видео, текстов и 3D (AR) 
                    интерактивной информацией, загруженной через редактор сценариев.</li>
                </ul>
            </div>
        </section>

        <section>
            <div className={C.date}>
                <header>Q4 2024</header>
            </div>
            <div className={C.content}>
                <div className={C.timelineDot}></div>
                <div className={C.mobileDate}>
                    <header>Q4 2024</header>
                </div>
                <h2>5-й ЭТАП</h2>

                <h3>КРОСС-ПЛАТФОРМЕННЫЙ ДВИЖОК ДЛЯ ДОПОЛНЕННОЙ РЕАЛЬНОСТИ</h3>

                <p>Цели 5-го ЭТАПА:</p>

                <ul>
                <li>Высокопроизводительный кросс-платформенный 3D движок для дополненной 
                    реальности с поддержкой существующих (Android, iOS) и возможностью 
                    подключения новых аппаратных платформ, включая поддержку носимых устройств (очков и шлемов дополненной реальности).</li>
                <li>Интеграция движка в единый программный комплекс управления контентом.</li>
                <li>Локализованные модули визуального программирования дополненной реальности, 
                    модуль управления пользовательским интерфейсом, модуль управления сценариями.</li> 
                </ul>
            </div>
        </section>

        <section className={C.lastSection}>
            <div className={C.date}>
                <header>Q2 2025</header>
            </div>
            <div className={C.content}>
                <div className={C.timelineDot}></div>
                <div className={C.mobileDate}>
                    <header>Q2 2025</header>
                </div>
                <h2>6-й ЭТАП</h2>

                <h3>ИНТЕГРИРОВАННЫЙ КРОСС-ПЛАТФОРМЕННЫЙ ЯЗЫК ПРОГРАММИРОВАНИЯ ДОПОЛНЕННОЙ РЕАЛЬНОСТИ</h3>

                <p>Цели 6-го ЭТАПА:</p>

                <ul>
                <li>Кроссплатформенный язык программирования для дополненной реальности, с поддержкой русского синтаксиса, 
                    возможность интеграции в существующие и перспективные национальные мобильные операционные системы и SLAM, 
                    компилятор языка программирования, высокопроизводительный интерпретатор (виртуальная машина), 
                    облачная многопользовательская среда разработки (IDE) с версионным контролем и файловой системой. </li>
                <li>Интеграция языка программирования в движок дополненной реальности для управления продуктами программного комплекса.</li>
                </ul>
            </div>
        </section>

        <section>
            <div className={C.date}>
            </div>
            <div className={C.content}>
                <div className={C.timelineDot}></div>
            </div>
        </section>
    </div>
    );
};
