import React from 'react';
import C from './Footer.module.scss';
import qrAndroid from '../../assets/images/QR_Android.png';
import qriOS from '../../assets/images/QR_iOS.png';
import group_1_0 from '../../assets/images/group1_0.svg';
import group_1_1 from '../../assets/images/group1_1.svg';
import group_1_2 from '../../assets/images/group1_2.svg';
import group_2_0 from '../../assets/images/group2_0_footer.svg';
import group_2_1 from '../../assets/images/group2_1_footer.svg';
import group_2_2 from '../../assets/images/group2_2_footer.svg';
import group_3_0 from '../../assets/images/group3_0.svg';
import group_3_1 from '../../assets/images/group3_1.svg';
import group_3_2 from '../../assets/images/group3_2.svg';
import group_4_0 from '../../assets/images/group4_0.svg';
import group_4_1 from '../../assets/images/group4_1.svg';
import group_4_2 from '../../assets/images/group4_2.svg';
import { useMediaQuery } from "@react-hook/media-query";
import { Media } from "../../models/media";

export const Footer = () => {
    const isMobile = useMediaQuery(`only screen and (max-width: ${Media.SM}px)`);

    return (
        <div className={C.Footer}>
            <div className={C.Footer__Wrap}>
                <div className={C.Footer__Left}>
                    <div className={C.Footer__Apps}>
                            <a
                                className={C.Footer__AppImage}
                                href="https://play.google.com/store/apps/details?id=io.spheroid.signofmemory&hl=ru&gl=US&pli=1"
                                target="_blank"
                                rel="noreferrer"
                            >
                                <img className={C.Footer__Qr} src={qrAndroid} alt="" />
                            </a>
                            <a className={C.Footer__AppImage} href="https://apps.apple.com/app/id1668879185" target="_blank" rel="noreferrer">
                                <img className={C.Footer__Qr} src={qriOS} alt="" />
                            </a>
                    </div>
                </div>

                <div className={C.Footer__Right}>
                    <div className={C.Footer__MarkersGrid}>
                        <div /> <div>Знак Памяти</div> <div>Фотоистория</div> <div>Искусство</div> <div>Выставка</div>

                        <div>Объект в разработкe</div> 
                        <img className={C.Footer__Group} src={group_1_0} alt="" />
                        <img className={C.Footer__GroupSmall} src={group_2_0} alt="" />
                        <img className={C.Footer__GroupSmall} src={group_3_0} alt="" />
                        <img className={C.Footer__Group} src={group_4_0} alt="" />

                        <div>Объект установлен<br/>в координатной точке</div> 
                        <img className={C.Footer__Group} src={group_1_1} alt="" />
                        <img className={C.Footer__GroupSmall} src={group_2_1} alt="" />
                        <img className={C.Footer__GroupSmall} src={group_3_1} alt="" />
                        <img className={C.Footer__Group} src={group_4_1} alt="" />

                        <div>Факт установки объекта<br/>подтверждён на месте</div> 
                        <img className={C.Footer__Group} src={group_1_2} alt="" />
                        <img className={C.Footer__GroupSmall} src={group_2_2} alt="" />
                        <img className={C.Footer__GroupSmall} src={group_3_2} alt="" />
                        <img className={C.Footer__Group} src={group_4_2} alt="" />

                    </div>

                    <p className={C.Footer__Desc}>
                        Скачайте SuperApp - мобильное приложение “Знак&nbsp;Памяти&nbsp;AR”
                        для&nbsp;просмотра памятных Знаков в&nbsp;дополненной
                        реальности и&nbsp;создания цифровой реконструкции местности
                    </p>
                </div>
            </div>

            {!isMobile && (
                <div className={C.Footer__Copy}>
                    Инициатор проекта: Проектная архитектурно-реставрационная 
                    группа <a href="https://noviygorod.org/" target="_blank"rel="noreferrer">
                        "Новый Город"
                    </a>
                </div>
            )}
        </div>
    );
};