import network from "../api/network";
import { ApiRequestMethodType } from "../models/api.model";

export const useHttp = () => {
    const request = async (
        method: ApiRequestMethodType = "get",
        url: string,
        body?: any
    ) => {
        // eslint-disable-next-line no-useless-catch
        try {
            const response = await network[method](url, body);

            if (response.status !== 200) {
                throw new Error(
                    `Could not fetch ${url}, status: ${response.statusText}`
                );
            }

            return await response.data.data;
        } catch (e) {
            throw e;
        }
    };
    return { request };
};
