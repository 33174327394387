import React from 'react';
import C from './Footer.module.scss';
import appStore from '../../assets/images/appStore.png';
import googlePlay from '../../assets/images/googlePlay.png';
import qrCode from '../../assets/images/qr-code.png';
import group_1_2 from '../../assets/images/group1_0.svg';
import group_1_0 from '../../assets/images/group1_1.svg';
import group_1_1 from '../../assets/images/group1_2.svg';
import status1 from '../../assets/images/footer/status1.svg';
import status2 from '../../assets/images/footer/status2.svg';
import status3 from '../../assets/images/footer/status3.svg';
import { useMediaQuery } from "@react-hook/media-query";
import { Media } from "../../models/media";

export const FooterSmall = () => {
    const isMobile = useMediaQuery(`only screen and (max-width: ${Media.SM}px)`);

    return (
        <div className={`${C.Footer} ${C.FooterSmall}`}>
            {!isMobile && (
                <div className={C.Footer__Copy}>
                    Инициатор проекта: Проектная архитектурно-реставрационная 
                    группа <a href="https://noviygorod.org/" target="_blank"rel="noreferrer">
                        "Новый Город"
                    </a>
                </div>
            )}
        </div>
    );
};