import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { useHttp } from "../hooks/http.hook";
import { ApiRequestMethodEnum, ApiRequestUrl, ApiStatusPendingEnum, ICard, } from "../models/api.model";
import network from "../api/network";


interface IRegistryState {
    registry: any;
    status: ApiStatusPendingEnum | null;
};

const initialState: IRegistryState = {
    registry: [],
    status: null,
};

export const getRegistry = createAsyncThunk("registry/getRegistry", 
    async (params: any) => {
        const { request } = useHttp();
        return await request(
            ApiRequestMethodEnum.GET,
            ApiRequestUrl.getRegistry,
            {params}
        );
    }
);

const registrySlice = createSlice({
    name: "registry",
    initialState,
    reducers: {

    },
    extraReducers: (builder) => {
        builder 
            .addCase(
                getRegistry.pending,
                (state) => {
                    state.status = ApiStatusPendingEnum.LOADING;
                }
            )
            .addCase(
                getRegistry.fulfilled,
                (state, action) => {
                    state.status = ApiStatusPendingEnum.LOADED;
                    state.registry = action.payload;
                }
            )
            .addCase(
                getRegistry.rejected,
                (state) => {
                    state.status = ApiStatusPendingEnum.ERROR;
                }
            )
    }
});

export default registrySlice.reducer;
