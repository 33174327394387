import React, { FC } from "react";
import C from './Layout.module.scss';

interface LayoutProps {
    children: React.ReactNode | React.ReactNode[];
}

export const LayoutPage: FC<LayoutProps> = ({ children }) => {

    return (
        <>
            <div className={C.Layout__ContentPage}>
                {children}
            </div>
        </>
    );
};
