import React from 'react';
import { AboutInfo } from "../../components/AboutInfo";
import { LayoutPage } from "../../containers/Layout";

export const About = () => {
    return (
        <LayoutPage>
            <AboutInfo />
        </LayoutPage>
    );
};