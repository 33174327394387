import { Button, Chip, InputLabel, TextField } from "@material-ui/core";
import { useForm } from "react-hook-form";
import "react-dadata/dist/react-dadata.css";
import C from "./MemoryForm.module.scss";
import React, { useCallback, useEffect, useRef, useState } from "react";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import { ApiStatusPendingEnum, ICard } from "../../models/api.model";
import { useAppDispatch, useAppSelector } from "../../hooks/useRedux";
import { createMemory, resetStatusForm } from "../../store/memorySlice";
import PhotoCameraIcon from '@mui/icons-material/PhotoCamera';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

export const MemoryForm = React.forwardRef(() => {
    const dispatch = useAppDispatch();

    const {
        handleSubmit,
    } = useForm<ICard>();

    const [ image, setImage ] = useState('');
    const [ group, setGroup ] = useState("1");
    const [ success, setSuccess ] = useState(false);
    const [ error, setError ] = useState(false);
    const form = useRef<HTMLFormElement>(null);
    const file = useRef<HTMLInputElement>(null);

    const status = useAppSelector(state => state.memory.statusCreate);

    const handleGroupChange = (event: React.ChangeEvent<{ value: any }>) => {
        setGroup(event.target.value as string);
    };

    const handleImageChange = (event: any) => {
        if (event?.target) {
            const objectUrl = URL.createObjectURL(event.target.files[0]);
            setImage(objectUrl);
        }
    };

    const handleClearImage = () => {
        if (file?.current) {
            file.current.value = "";
        }
        setImage('');
    };

    const onSubmitForm = useCallback(
        () => {
            if (!!form.current) {
                const data = new FormData(form?.current);
                dispatch(createMemory(data));
            }
        },
        [ dispatch ]
    );

    useEffect(() => {
        if (form.current && status === ApiStatusPendingEnum.LOADED) {
            form.current.reset();
            setImage('');
            setSuccess(true);

            const successTimeOut = setTimeout(() => {
                setSuccess(false);
                dispatch(resetStatusForm());
            }, 3000);

            return () => clearTimeout(successTimeOut);
        }

        if (status === ApiStatusPendingEnum.ERROR) {
            setError(true);
            const errorTimeOut = setTimeout(() => {
                setError(false);
                dispatch(resetStatusForm());
            }, 3000);

            return () => clearTimeout(errorTimeOut);
        }
    }, [ dispatch, status ]);

    return (
        <>
            {!success && !error && (
                <form className={C.MemoryForm} ref={form} onSubmit={handleSubmit(onSubmitForm)}>
                    <h2 className={C.Title}>Добавить свой знак памяти</h2>

                    <div className={C.Input}>
                        <TextField
                            name="contributor_name"
                            label="Ваше имя"
                            variant="outlined"
                            fullWidth
                            required
                        />
                    </div>

                    <div className={C.Input}>
                        <TextField
                            name="contributor_contacts"
                            label="E-mail"
                            variant="outlined"
                            fullWidth
                            required
                            type="email"
                        />
                    </div>

                    <div className={C.Input}>
                        <TextField
                            name="name"
                            label="Название"
                            variant="outlined"
                            fullWidth
                            required
                        />
                    </div>

                    <div className={C.Input}>
                        <TextField
                            name="description"
                            label="Описание"
                            multiline
                            minRows={4}
                            maxRows={4}
                            variant="outlined"
                            fullWidth
                            required
                        />
                    </div>

                    <div className={C.Input}>
                        <TextField
                            name="address"
                            label="Адрес"
                            variant="outlined"
                            fullWidth
                            required
                        />
                    </div>

                    <div className={C.Input}>
                        <TextField
                            name="x"
                            label="Широта"
                            variant="outlined"
                            fullWidth
                            required
                        />
                    </div>

                    <div className={C.Input}>
                        <TextField
                            name="y"
                            label="Долгота"
                            variant="outlined"
                            fullWidth
                            required
                        />
                    </div>

                    <div className={C.Input}>
                        {!!image?.length && <img src={image} alt="" />}
                        <label>
                            <input ref={file} name="preview_img" type="file" hidden onChange={handleImageChange} />
                            <Chip icon={<PhotoCameraIcon />} label="Изображение" />
                        </label>
                        {!!image?.length && <Button onClick={handleClearImage}>Очистить</Button>}
                    </div>

                    <div className={C.Input}>
                        <FormControl variant="outlined" fullWidth>
                            <InputLabel>Группа</InputLabel>
                            <Select
                                name="group"
                                value={group}
                                onChange={handleGroupChange}
                                label="Группа"
                                required
                            >
                                <MenuItem value={1}>Знак памяти</MenuItem>
                                <MenuItem value={2}>Фотоистория</MenuItem>
                            </Select>
                        </FormControl>
                    </div>

                    <div>
                        <Button
                            variant="contained"
                            color="primary"
                            type="submit"
                            disabled={status === ApiStatusPendingEnum.LOADING}
                        >
                            Отправить
                        </Button>
                    </div>
                </form>
            )}

            {success && (
                <div className={C.MemoryForm}>
                    <div className={C.Alert}>
                        <CheckCircleIcon className={C.SuccessInput} />
                        <h2>Форма успешно отправлена!</h2>
                    </div>
                </div>
            )}

            {error && (
                <div className={C.MemoryForm}>
                    <CheckCircleIcon className={C.ErrorInput} />
                    <h2>Ошибка отправки формы!</h2>
                </div>
            )}
        </>
    );
});
