import React, { useEffect, useState, useCallback } from "react";
import { useAppDispatch, useAppSelector } from "../../hooks/useRedux";
import { NavLink } from "react-router-dom";
import { LayoutPage } from "../../containers/Layout";
import { getProjects } from "../../store/projectsSlice";
import C from "./Projects.module.scss";


export const Projects = () => {
    const dispatch = useAppDispatch();
    const projects = useAppSelector(state => state.projects.projects);

    useEffect(() => {
        dispatch(getProjects());
    }, [ dispatch ]);

    return (
        <LayoutPage>
            <h1>ИНИЦИАТИВНЫЕ И ПАРТНЕРСКИЕ ПРОЕКТЫ</h1>
            {
                projects.map((d: any) => (
                <div className={C.ProjectShort} key={d.id}>
                    <h3 className={C.LinkHeader}>
                    <a href={`#${d.slug}`}>{d.name}</a>:
                    </h3>
                    
                    <div dangerouslySetInnerHTML={{__html: d.short_description}}/>
                </div>
                ))
            }
            <div className={C.Divider}>
                ...
            </div>

            <p>Согласно концепции, на платформе "Знак Памяти" любой пользователь сможет устанавливать свои AR-объекты,
               соответствующие тематике платформы.</p>

            <p>Однако сейчас, когда проект только стартует, о такой возможности и о том, как это сделать практически, 
               знает лишь небольшое количество тестировщиков и друзей проекта.</p> 

            <p>С целью отработки практических решений и помощи будущим пользователям мы инициировали такую практику - 
               создание отдельных тематических AR-проектов.</p>

            <p>Представляем тематические проекты на платформе "Знак Памяти":</p>

            {
                projects.map((d: any) => (
                <div className={C.ProjectMedium} key={d.id} id={d.slug}>
                    <h3>{d.name}</h3>
                    <div dangerouslySetInnerHTML={{__html: d.medium_description}}/>
                </div>
                ))
            }

        </LayoutPage>
    );
};
