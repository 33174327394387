import React, { FC } from "react";
import { Header } from "../../components/Header";
import { FooterSmall } from "../../components/Footer";
import { useAppSelector } from "../../hooks/useRedux";
import { Menu } from "../Menu";
import { useMediaQuery } from "@react-hook/media-query";
import { Media } from "../../models/media";
import C from './Layout.module.scss';

interface LayoutProps {
    children: React.ReactNode | React.ReactNode[];
}

export const LayoutStandalonePage: FC<LayoutProps> = ({ children }) => {
    const isOpenMenu = useAppSelector((state) => state.userInterface.isOpenMenu);
    const isMobile = useMediaQuery(`only screen and (max-width: ${Media.SM}px)`);
    return (
        <>
            <div className={C.Layout}>
                <Header />

                <main className={C.Layout__ContentSingle}>
                    {children}
                </main>

                <FooterSmall />
            </div>

            <Menu open={isOpenMenu} />
        </>
    );
};
