import React from 'react';
import { NavLink } from "react-router-dom";

export const AboutInfo = () => {
    return (
        <div>
            <h1>О ПЛАТФОРМЕ «ЗНАК ПАМЯТИ»</h1>

            <h3>КОНЦЕПЦИЯ</h3>

            <p>В основе замысла - идея актуальности общественно-доступного инструментария для "воссоздания" 
               утраченных объектов культурного наследия (памятников истории и культуры) путем размещения 
               их виртуальных реконструкций в реальных географических точках на их исторических местах. 
               Такую возможность дают технологии дополненной и расширенной реальности (AR/XR).</p>

            <p>Дополненная реальность не имеет ограничений городской застройки, а
                создание виртуального объекта гигантского размера не требует капитальных затрат и высоких
                эксплуатационных расходов.</p>

            <p>Поэтому в дополненной реальности мы можем (гипотетически) воспроизвести все важные для нашей истории и
                культуры пространственные объекты, уходя как угодно далеко и детально в прошлое, а также в настоящее и
                будущее, помещая в расширенную реальность то, чего в ней никогда не было, например, проекты будущих
                зданий и сооружений, и современные мемориальные объекты, не предполагающие воплощения в материале.</p>

            <p>Таким образом, мы строим общедоступную некоммерческую платформу дополненной реальности "Знак Памяти" для
                размещения и просмотра виртуальных цифровых объектов, имеющих объективно-исторические основания
                сохранения памяти об историческом и культурном наследии, событиях, лицах и явлениях, связанных с
                конкретным историческим местом на территории Российской Федерации.</p>

            <p>Главный критерий выбора объектов - желание и подготовка материалов самими участниками и пользователями -
                увлеченными людьми и профессионалами, сообществами и организациями России.</p>


            <h3>КАК ЭТО РАБОТАЕТ?</h3>

            <p>На текущем этапе любой пользователь / участник проекта, заинтересованный в установке конкретного
                виртуального объекта (реконструкции, памятника, фотографии и др.) присылает на вебсайт платформы
                необходимые материалы (форма в разделе <NavLink to="/add-marker"><strong>РАЗМЕСТИТЬ СВОЙ AR ОБЪЕКТ</strong></NavLink>).
                <br />
                Затем другие участники, наделенные правами администрирования веб-реестра, редактируют информацию и
                публикуют объект в веб-реестре (объект появляется в поиске и на интерактивной карте), после этого они
                размещают объект в дополненной реальности. Объект становится виден в заданной географической точке
                через камеры смартфонов и планшетов 
                (подробнее в разделе <NavLink to='/about-app'><strong>О МОБИЛЬНОМ ПРИЛОЖЕНИИ "ЗНАК ПАМЯТИ AR"</strong></NavLink>).</p>

            <p>На следующих этапах развития платформы пользователи смогут сами публиковать свои объекты в веб-реестре, а
                затем устанавливать их в дополненной реальности, используя свои домашние компьютеры.</p>

            <p>Чтобы увидеть AR-объект, пользователь должен находиться вблизи географической точки, в
                координатах которой объект установлен, включить на своем смартфоне или планшете приложение "Знак Памяти
                AR" и найти этот объект через камеру своего смартфона или планшета. Если объект находится от
                пользователя на расстоянии не более 500м, встроенный в приложение радар подскажет направление
                расположения объекта. Рекомендуем заранее изучить веб-карту, чтобы понять где в Вашем городе или
                местности установлены AR-объекты платформы "Знак Памяти".</p>

            <p>Если пользователь физически не может посетить место нахождения AR-объекта, он может получить всю
                информацию о нем в веб-реестре, а примеры установленных AR-объектов может посмотреть в дополненной
                реальности рядом с собой, выбрав <strong>"Демо"</strong> в приложении "Знак Памяти AR"</p>

            <h3>КАКИЕ ОБЪЕКТЫ МОЖНО УСТАНАВЛИВАТЬ?</h3>

            <p>В дополненной реальности платформы "Знак Памяти" могут быть размещены виртуальные объекты в широком
                смысле относящиеся к событиям истории России.
            </p>

            <p>
                Примеры типов объектов, уже установленных, либо находящихся в разработке:
            </p>

            <ul>
                <li><strong>"Реконструкция"</strong> &mdash; 3D-реконструкция утраченного объекта архитектуры (памятника истории и
                    культуры),
                    установленная на его историческом или ином памятном месте в масштабе 1:1. 
                    <br />
                    Реконструкции представляют собой трудоёмкие и, следовательно, единичные объекты, 
                    создание которых требует продолжительного времени и существенных затрат от инициаторов (и спонсоров).
                    <br />
                    Мы рекомендуем инициаторам создания реконструкции, в качестве первого шага, сразу обозначить нужное место и свои планы "закладным камнем" &mdash;
                    виртуальным Знаком Памяти, который устанавливается в приложениях просто и быстро.
                </li>

                <li><strong>"Знак Памяти"</strong> &mdash; виртуальный памятник, установленный в память об утраченном памятнике истории и культуры, 
                    либо о событии, лице, явлении давней или современной истории, включая значимые местные поводы.
                    <br />
                    На первом этапе проекта мы сосредоточены именно на Знаках Памяти, поскольку они достаточно просты в воплощении и могут отмечать большое количество актуальных событий.
                    <br />
                    Тема, форма, размер и содержание Знака Памяти, а также место его установки  определяются пользователями - инициаторами.
                    <br />
                    Для упрощения мы разрабатываем и бесплатно предоставляем несложные 3D модели. Пользователь сам решает, выбрать ли ему нашу модель или создать собственную.
                </li>

                <li><strong>"Выставка"</strong> &mdash; 3D-инсталляция, представляющая собой самостоятельную выставку различных объектов (фотографий, произведений искусства и пр.) 
                    в дополненной реальности, либо являющаяся AR-частью мультимедийного проекта. Выставка может быть временной или постоянной.
                </li>

                <li><strong>"Портал"</strong> &mdash; видимые в дополненной реальности виртуальные ворота (дверь, стена и т.п.), 
                    физически пройдя сквозь которые, пользователь попадает внутрь виртуального пространства (помещения) 
                    и видит виртуальную сцену (выставку, анимацию, панораму, фото и видео 360 и т.д.), как правило, в образовательных целях.
                </li>

                <li><strong>"Фотоистория"</strong> &mdash; исторический фотоснимок (или серия снимков), помещенный в дополненную реальность вблизи места, где был сделан снимок.
                </li>

                <li><strong>"Искусство"</strong> &mdash; репродукция художественного произведения (графика, живопись), 
                    помещенное в дополненную реальность вблизи места, изображеннного на произведении.
                </li>

                <li>Аудиовизуальные сообщения и композиции, связанные с другими виртуальными объектами или самостоятельные.</li>

                <li>Знаки визуальной и звуковой коммуникации в дополненной реальности.</li>
            </ul>

            <h3>МОДЕРАЦИЯ КОНТЕНТА</h3>

            <p>
                Объекты и информация, размещаемые пользователями, проходят модерацию для предотвращения публикаций противоправного, оскорбительного и тематически неоправданного характера.
                <br /> 
                На первом (тестовом) этапе проекта это будет премодерация. В этот период в сеть (на вебсайт и в AR) информация будет выкладываться 
                только администраторами проекта или проверенными пользователями.
                <br />
                На втором этапе, когда материалы в сеть будут выкладываться неограниченным кругом зарегистрировавшихся пользователей, будет применяться постмодерация 
                на основе отзывов самих пользователей и в соответствии с публикуемой контентной политикой платформы.
            </p>
        </div>
    );
};