import { configureStore } from '@reduxjs/toolkit';
import memory from './memorySlice';
import userInterface from './userInterfaceSlice';
import registry from './registrySlice';
import projects from './projectsSlice';

const store = configureStore({
    reducer: {
        memory,
        userInterface,
        registry,
        projects,
    },
});

export default store;

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;