import React from 'react';
import { MemoryForm } from "../../containers/memoryForm";
import C from './AddMarker.module.scss';

export const AddMarker = () => {
    return (
        <div className={C.AddMarker}>
            <MemoryForm />
        </div>
    );
};