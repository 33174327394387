import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { useHttp } from "../hooks/http.hook";
import { ApiRequestMethodEnum, ApiRequestUrl, ApiStatusPendingEnum, ICard, } from "../models/api.model";
import network from "../api/network";


interface IProjectsState {
    projects: any;
    projectDetails: any;
    status: ApiStatusPendingEnum | null;
    statusDetails: ApiStatusPendingEnum | null;
};

const initialState: IProjectsState = {
    projects: [],
    projectDetails: null,
    status: null,
    statusDetails: null,
};

export const getProjects = createAsyncThunk("projects/getProjects", 
    async () => {
        const { request } = useHttp();
        return await request(
            ApiRequestMethodEnum.GET,
            ApiRequestUrl.getProjects
        );
    }
);

export const getProjectDetails = createAsyncThunk("project/getProjectDetails", async (project_id: string) => {
    const { request } = useHttp();
    return await request(ApiRequestMethodEnum.GET, ApiRequestUrl.getProjects + project_id);
});

const projectsSlice = createSlice({
    name: "projects",
    initialState,
    reducers: {

    },
    extraReducers: (builder) => {
        builder 
            .addCase(
                getProjects.pending,
                (state) => {
                    state.status = ApiStatusPendingEnum.LOADING;
                }
            )
            .addCase(
                getProjects.fulfilled,
                (state, action) => {
                    state.status = ApiStatusPendingEnum.LOADED;
                    state.projects = action.payload;
                }
            )
            .addCase(
                getProjects.rejected,
                (state) => {
                    state.status = ApiStatusPendingEnum.ERROR;
                }
            )

            .addCase(
                getProjectDetails.pending,
                (state) => {
                    state.statusDetails = ApiStatusPendingEnum.LOADING;
                })
            .addCase(
                getProjectDetails.fulfilled,
                (state, action: PayloadAction<any>) => {
                    state.statusDetails = ApiStatusPendingEnum.LOADED;
                    state.projectDetails = action.payload;
                }
            )
            .addCase(
                getProjectDetails.rejected,
                (state) => {
                    state.statusDetails = ApiStatusPendingEnum.ERROR;
                })

    }
});

export default projectsSlice.reducer;
