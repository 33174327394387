export const baseURL = (
   (process.env.NODE_ENV === "development") ? 
    "http://localhost:8001" : "https://znak-pamyati.ru"
);

/**
 * Состояния загрузки
 */
export enum ApiStatusPendingEnum {
    LOADING = "LOADING",
    LOADED = "LOADED",
    ERROR = "ERROR",
}

/**
 * Названия методов
 */
export type ApiRequestMethodType = "get" | "post" | "put" | "delete";

export enum ApiRequestMethodEnum {
    GET = "get",
    POST = "post",
    PUT = "put",
    DELETE = "delete",
}

/**
 * @description Эндпоинты API
 * @property getCategory - Запрос всех памятников
 */
export const ApiRequestUrl = {
    getMemory: "/signs/",
    createMemory: "/proposals/",
    getMemoryPage: "/signs/", // assuming sign_id will be joined
    getRegistry: "/registry/",
    getProjects: "/projects/",
};

/**
 * @description Маркер
 * @property id             - id
 * @property group          - Номер группы (знак памяти, фотоистория...)
 * @property name           - Название памятника
 * @property address        - Адрес
 * @property x              - Ширина
 * @property y              - Долгота
 * @property description    - Описание
 * @property link           - Ссылка
 * @property preview_img    - Изображение
 * @property status         - Статус
 */
export interface ICard {
    id?: number;
    group: number;
    name: string;
    address: string;
    x?: number;
    y?: number;
    lng: number;
    lat: number;
    description: string;
    link?: null | string;
    preview_img: any;
    status: number;
}
