import React, { useCallback, useEffect } from 'react';
import C from './Header.module.scss';
import logo from '../../assets/images/logo.svg';
import singleLogo from '../../assets/images/singleLogo.png';
import close from '../../assets/images/close.svg';
import { useMediaQuery } from "@react-hook/media-query";
import { Media } from "../../models/media";
import searchIcon from "../../assets/images/search.svg";
import menuIcon from "../../assets/images/menu.svg";
import { useAppDispatch, useAppSelector } from "../../hooks/useRedux";
import { closeSearch, openSearch } from "../../store/memorySlice";
import { closeModal, openModal } from "../../store/userInterfaceSlice";
import { NavLink } from "react-router-dom";

export const Header = () => {
    const dispatch = useAppDispatch();
    const isOpenSearch = useAppSelector((state) => state.memory.isMobileSearch);
    const isOpenMenu = useAppSelector((state) => state.userInterface.isOpenMenu);
    const isMobile = useMediaQuery(`only screen and (max-width: ${Media.SM}px)`);

    useEffect(() => {
        if (!isMobile && isOpenSearch) {
            dispatch(closeSearch());
        }
    }, [ dispatch, isMobile, isOpenSearch ]);

    const handleToggleSearch = useCallback(() => {
        isOpenSearch ? dispatch(closeSearch()) : dispatch(openSearch());
    }, [ dispatch, isOpenSearch ]);

    const handleToggleMenu = useCallback(() => {
        isOpenMenu ? dispatch(closeModal()) : dispatch(openModal());
    }, [ dispatch, isOpenMenu ]);

    return (
        <div className={C.Header}>
            <div className={C.Header__Wrap}>
                <NavLink to="/" className={C.Header__Left}>
                    <img className={`${C.Header__Logo} ${isOpenSearch && C.Header__LogoMini}`}
                         src={isOpenSearch ? singleLogo : logo} alt="Знак памяти" />
                    {!isOpenSearch && (
                        <div className={C.Header__Desc}>В ДОПОЛНЕННОЙ РЕАЛЬНОСТИ <b>РОССИИ</b></div>
                    )}
                </NavLink>


                <div className={C.Header__Buttons}>
                    {isMobile && (
                        <button
                            className={C.Header__Button}
                            onClick={handleToggleSearch}
                        >
                            <img className={C.Header__ButtonIcon} src={!isOpenSearch ? searchIcon : close} alt="" />
                        </button>
                    )}
                    {!isOpenSearch && (
                        <button
                            className={C.Header__Button}
                            onClick={handleToggleMenu}
                        >
                            <img className={C.Header__ButtonIcon} src={!isOpenMenu ? menuIcon : close} alt="" />
                        </button>
                    )}
                </div>

            </div>
        </div>
    );
};