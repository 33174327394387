import { FC } from "react";
import C from "./SignHistoryItem.module.scss";
import {signTypeDisplay, signStatusDisplay, signIconUrl} from '../../models/statuses';


export const SignHistoryItem: FC<any> = ({group, item}) => {
    var date = new Date(item.date).toLocaleDateString(
        'ru-RU', 
        {
            month: 'long', day: 'numeric', year: 'numeric'
        }
    );
    return (
        <section className={C.SignHistoryItem}>
            {item.status !== null && (<img className={C.statusIcon} src={signIconUrl(group, item.status)}/>)}
            <div className={C.date}>{date} {item.status !== null && (<span>&mdash; {signStatusDisplay[item.status]}</span>)}</div>
            <div dangerouslySetInnerHTML={{__html: item.description}} />
        </section>
    )
};
