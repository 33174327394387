import axios from "axios";
import { baseURL } from "../models/api.model";

const network = axios.create({
    headers: {
        Accept: "application/json, text/plain, multipart/form-data, */*",
        "Content-Type": "application/json",
    },
    baseURL: `${baseURL}/api`,
});

if (process.env.NODE_ENV === "development") {
    network.interceptors.request.use(
        function (config) {
            // Do something before request is sent
            console.log("out:", config);
            return config;
        },
        function (error) {
            // Do something with request error
            console.log("out error:", error);

            return Promise.reject(error);
        }
    );

    network.interceptors.response.use(
        function (response) {
            console.log("in:", response);
            return response;
        },
        function (error) {
            console.log(error.response);
        }
    );
}

export default network;
