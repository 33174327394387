
export const signTypeDisplay: { [index: number]: string } = {
    1: 'Знак памяти',
    2: 'Фотоистория',
    3: 'Искусство',
    4: 'Выставка',
};

export const signStatusDisplay : { [index: number]: string } = {
    0: 'Знак в разработке',
    1: 'Знак установлен',
    2: 'Факт установки подтверждён',
};


export function signIconUrl(group: string, status: string) {
    return require(`../assets/images/group${group}_${status}.svg`);
}
